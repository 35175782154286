export const getSkillsData = () => {
	return [
		{
			category: "Languages",
			skills: [
				"Java",
				"Python",
				"C++",
				"C#",
				"Rust",
				"TypeScript",
				"JavaScript",
				"ES6",
			],
		},
		{
			category: "Web Frontend",
			skills: [
				"HTML5",
				"CSS3",
				"AJAX",
				"JQuery",
				"React",
				"Angular",
				"Vue",
				"UI/UX",
				"Node.js",
				"Microservices",
				"CSS",
				"Bootstrap",
				"Redux",
				"TDD",
			],
		},
		{
			category: "Web Services",
			skills: ["REST", "SOAP", "JSON", "API", "GraphQL", "RESTful APIs"],
		},
		{
			category: "Web/App Servers",
			skills: ["Apache/Tomcat", "Kestrel", "IIS", "Nginx"],
		},
		{
			category: "Cloud Services & Platforms",
			skills: [
				"AWS",
				"Google Analytics",
				"Google Colab",
				"PowerBI",
				"Firebase",
				"Amazon SageMaker",
				"Azure",
				"GCP",
			],
		},
		{
			category: "Design Patterns",
			skills: [
				"Strategy",
				"State",
				"Proxy",
				"Observer",
				"Prototype/Singleton",
				"Chain of Responsibility",
				"Command",
			],
		},
		{
			category: "Frameworks & Libraries",
			skills: [
				"Spring",
				"Hibernate",
				"Entity Framework",
				"gRPC",
				"LINQ",
				"Scikit-learn",
				"Netflix OSS",
				"Java EE",
				"Jakarta EE",
				"Hadoop",
				"JMS",
				"Kafka",
				"Eureka",
			],
		},
		{
			category: "Security",
			skills: ["Web Security", "OAuth 2.0", "JWT", "OpenID", "OIDC"],
		},
		{
			category: "Databases & Data Storage",
			skills: [
				"MS SQL Server",
				"Oracle",
				"MySQL",
				"PostgreSQL",
				"MongoDB",
				"DynamoDB",
				"Cassandra",
				"ElasticSearch",
				"Redis",
				"Knex.js",
				"ORM",
			],
		},
		{
			category: "Testing & Quality Assurance",
			skills: [
				"JUnit",
				"NUnit",
				"Cucumber",
				"Selenium",
				"Mockito",
				"Cypress",
				"Jest",
				"RTL",
			],
		},
		{
			category: "Development Tools & Version Control",
			skills: [
				"Git",
				"GitHub",
				"GitLab",
				"Gradle",
				"Maven",
				"Yarn",
				"GitHub Actions",
			],
		},
		{
			category: "DevOps & CI/CD",
			skills: ["Docker", "Kubernetes", "Jenkins", "Ansible", "Terraform"],
		},
		{
			category: "Project Management & Collaboration",
			skills: ["JIRA", "Figma", "Confluence", "Agile/Scrum"],
		},
		{
			category: "Logging & Monitoring",
			skills: [
				"Elasticsearch",
				"Grafana",
				"Prometheus",
				"SonarQube",
				"Splunk",
			],
		},
		{
			category: "Data Science",
			skills: [
				"Data Warehouse",
				"Data Pipeline",
				"Data Engineer",
				"Data Mining",
				"Data Visualization",
				"Data Analysis",
				"Scikit-learn",
				"NLP",
				"Pandas",
				"NumPy",
				"TensorFlow",
				"PyTorch",
				"Tableau",
				"Spark",
			],
		},
		{
			category: "Architectures",
			skills: [
				"Event-Driven",
				"Microservices",
				"Serverless",
				"BDD",
				"TDD",
				"Relational Database",
			],
		},
		{
			category: "Platforms",
			skills: [
				"Windows",
				"Mac OS",
				"Unix",
				"Linux",
				"Jupyter",
				"Anaconda",
			],
		},
		// ... more categories and skills as needed
	];
};
